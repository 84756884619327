body {
  font-family: "Open Sans", sans-serif !important;
  color: $text-color;
}

h2,
h4 {
  color: $primary-color;
}

h2 {
  margin-bottom: 10px;
}

ul {
  margin: 0;
  padding: 10px 0;
}

li {
  margin-left: 20px;
}

.custom-bullet li {
  display: block;
}

.custom-bullet li:before {
  /*Using a Bootstrap glyphicon as the bullet point*/
  content: ">";
  font-family: "Glyphicons Halflings";
  font-size: 12px;
  float: left;
  margin-top: 3px;
  margin-left: -17px;
  color: $primary-color;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: underline;
}

iframe {
  border: none;
}

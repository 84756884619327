
@media only screen and (max-width: 1200px) {

    .long_banner{
      display: none;
    }

    .banner{
      padding-left: 30px;
    }
  }
#about {
  li {
    margin-bottom: 15px;
  }

  div .custom-bullet a {
    color: #eed46a !important;
  }
}

.lead a {
  color: rgba(255, 255, 255, 0.99) !important;
}

.line p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.insta a {
  color: $primary-color !important;
}

.anim {
  padding-top: 10px;
}

#googleWeb {
  background-color: #f8f9fa !important;
}
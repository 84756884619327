#work h2{
    margin-bottom: 20px;
}

#homeVideo button.btn.btn-default {
    background: black;
    border-radius: 50%;
    position: absolute;
    right: 0;
    z-index: 5;
    color: white;
}
